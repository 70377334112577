<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="h3">
        Approval Diskon & Voucher
      </h1>
    </div>
    <a-row :gutter="24" class="mt-4">
      <a-col :span="12">
        <label class="mb-1">&nbsp;</label>
        <a-input-search
          v-model="params.search"
          size="large"
          :placeholder="$t('discount.search')"
          :loading="loading"
          :disabled="loading"
          allow-clear
          @change="handleSearch"
        >
          <a-button slot="enterButton" class="bg-primary-blue text-white">
            {{ $t('utils.search') }} <a-icon type="search" />
          </a-button>
        </a-input-search>
      </a-col>
      <a-col :span="6" :offset="2">
        <label class="mb-1">{{ $t('discount.period') }}</label>
        <a-range-picker v-model="dates" format="DD MMM YYYY" size="large" @change="onChangeDate" />
      </a-col>
      <a-col :span="4">
        <label class="mb-1">{{ $t('order.sortBy') }}</label>
        <a-dropdown v-model="visible" :trigger="['click']" style="width: 100%">
          <a-menu slot="overlay" @click="onMenuClick">
            <a-menu-item key="1">
              <span class="dropdown-title">{{ $t('discount.sort_by') }}</span>
            </a-menu-item>
            <a-menu-item key="2">
              <a-checkbox-group
                class="sorting-checkbox"
                :options="sortOptions"
                :value="['createdAt']"
                @change="onSortChange($event, 'sort')"
              />
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="3">
              <span class="dropdown-title">{{ $t('discount.order_by') }}</span>
            </a-menu-item>
            <a-menu-item key="4">
              <a-checkbox-group
                class="sorting-checkbox"
                :options="orderOptions"
                :value="orders"
                @change="onSortChange($event, 'order')"
              />
            </a-menu-item>
          </a-menu>
          <a-button size="large" class="d-flex align-items-center fs-14">
            {{ $t(`discount.${params.sort}`) }}
            <a-icon class="ml-auto" type="down" />
          </a-button>
        </a-dropdown>
      </a-col>
    </a-row>
    <div class="mt-4">
      <a-tabs
        class="pca-tab-store"
        :default-active-key="$route.query.type || 'DISCOUNT'"
        @change="handleType"
      >
        <a-tab-pane
          key="DISCOUNT"
          :tab="`${$t('discount.discount')}`"
        >
          <DiscountTable 
            :data-list="data_list"
            :t-head="t_head_discount"
            :permission="permission"
            @handleShowModal="handleShowModal"
            @toDetail="toDetail"
          />
        </a-tab-pane>

        <a-tab-pane
          key="VOUCHER"
          :tab="`${$t('discount.voucher')}`"
        >
          <VoucherTable 
            :data-list="data_list"
            :t-head="t_head"
            :permission="permission"
            @handleShowModal="handleShowModal"
            @toDetail="toDetail"
          />
        </a-tab-pane>
      </a-tabs>
    </div>

    <Pagination
      class="text-right"
      :total="pagination.total_items"
      :page-size="pagination.size"
      :total-length-per-page="data_list.length"
      :default-page="page"
      @changePage="changePage"
    />

    <ModalConfirmation 
      v-if="modal.show"
      :modal="modal"
      @handleSubmit="handleSubmit"
      @handleShowModal="handleShowModal"
    />
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, reactive, ref } from 'vue'

import approval from './composable/approval.js'
import DiscountTable from './partials/DiscountTable.vue'
import VoucherTable from './partials/tabelvoucher.vue'
import Pagination from '@/components/Pagination/index.vue'
import ModalConfirmation from './partials/confirmationModal.vue'

import router from '@/router'

const {
  data_list,
  t_head,
  t_head_discount,
  page,
  pagination,
  modal,
  loading,
  changePage,
  handleType,
  handleSearch,
  handleShowModal,
  handleSubmit,
  toDetail,
} = approval()

const { $i18n, $route, $router, $store, $moment } = getCurrentInstance().proxy.$root

const INITIAL_PARAMS = {
  search: '',
  start_date: '',
  end_date: '',
  status: 'ALL',
  sort: 'createdAt',
  order: 'DESC',
  page: 1,
  limit: 10,
}

// Data
const dates = router.app._route.query.start_date
  ? ref([router.app._route.query.start_date, router.app._route.query.end_date])
  : ref([])
const visible = ref(false)
const orders = router.app._route.query.order
  ? ref([router.app._route.query.order])
  : ref(['DESC'])
const sortOptions = ref([
  { label: $i18n.t('discount.createdAt'), value: 'createdAt' },
])
const orderOptions = ref([
  { label: $i18n.t('discount.newest'), value: 'DESC' },
  { label: $i18n.t('discount.oldest'), value: 'ASC' },
])
const params = reactive(INITIAL_PARAMS)

// Computed
const permission = computed(() => $store.getters['user/can']('approval'))

// Methods
const onMenuClick = (e) => {
  console.log(e)
  if (e.key !== '2' && e.key !== '4') {
    visible.value = false
  }
}

const onSortChange = (value, type) => {
  if(type === 'order' && value.length) {
    const newValue = !router.app._route.query.order || router.app._route.query.order === 'DESC'
      ? 'ASC'
      : 'DESC'
    orders.value = [newValue]
    $router.push({
      query: {
        ...router.app._route.query,
        order: newValue,
      },
    })
  }
}

const onChangeDate  = (date) => {
  $router.push({
    query: {
      ...router.app._route.query,
      start_date: date[0] ? $moment(date[0]).startOf('day').format() : '',
      end_date: date[1] ? $moment(date[1]).endOf('day').format() : '',
      page: undefined,
    },
  })
}
</script>

<style lang="scss">
.fs-14 {
  font-size: 14px !important;
}
.fw-bold {
  font-weight: bold;
}
.text-capitalize {
  text-transform: capitalize
}
.sorting-checkbox {
  .ant-checkbox-group-item {
    display: block !important;
    padding: 5px 0;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-checkbox-inner {
    border-color: transparent !important;
  }
  .ant-checkbox-checked {
    &::after {
      border-color: transparent !important;
    }
    .ant-checkbox-inner {
      background-color: transparent !important;

      &::after {
        border-color: #ff0a54 !important;
      }
    }
  }
}
.dropdown-title {
  font-size: 12px;
  font-weight: bold;
  color: #6c757d;
}
.discount-list div.ant-table-body {
  min-height: 360px;
}
.ant-btn-info {
  color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.ant-dropdown-menu-item {
  background: #fff !important;
}
.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected > td {
    background-color: #fff !important;
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>
