<template>    
  <TableBorder>
    <template #thead>
      <th v-for="th in tHead" :key="th" class="h-48px" scope="col">
        <div v-if="th === 'checkbox'"> 
          <a-checkbox />
        </div>
        <div v-else-if="th === 'action'"> 
          {{ '' }}
        </div>
        <div v-else>
          {{ $t('store_list_page.' + th) }}
        </div>
      </th>
    </template>
    <tbody>
      <tr v-for="val in dataList" :key="val.id">
        <td>
          <a-checkbox />
        </td>
        <td>
          <div class="program">
            {{ val.code }}
          </div>
          <div class="type">
            {{ val.type }}
          </div>
        </td>
        <td>
          {{ val.promotion_name }}
        </td>
        <td>
          {{ $moment(val.start_date.replace(' ','')).format('DD MMM YYYY HH:mm') + ' - ' + $moment(val.end_date.replace(' ','')).format('DD MMM YYYY HH:mm') }}
        </td>
        <td>
          {{ val.quota }}
        </td>
        <td>
          <a-tag :color="val.status === 'WAITING_APPROVAL' ? 'orange' : val.status === 'ACTIVE' ? 'blue' : 'red'">
            {{ $t(`discount.${val.status.toLowerCase()}`) }}
          </a-tag>
          <a-popover v-if="val.status === 'INACTIVE' && val.reason" title="Alasan Pembatalan" style="font-size: 14px; cursor: pointer">
            <template slot="content">
              {{ val.reason }}
            </template>
            <a-icon type="info-circle" style="color: #FF0A54" />
          </a-popover>
        </td>
        <td>
          <a-dropdown
            v-if="permission.includes('READ') || permission.includes('WRITE')"
            style="float: right"
            :overlay-style="{ width: '150px' }"
          >
            <a @click.prevent="">
              <a-icon type="more" />
            </a>

            <template #overlay>
              <a-menu>
                <a-menu-item class="action py-2" @click="$emit('toDetail', val.id, 'voucher', 'detail')">
                  <a-icon type="eye" />
                  {{ $t('store_list_page.view_detail') }}
                </a-menu-item>
                <a-menu-item
                  v-if="val.status === 'WAITING_APPROVAL' && permission.includes('WRITE')"
                  class="action py-2"
                  @click="$emit(
                    'handleShowModal',
                    {
                      ...val,
                      show: true, 
                      action: 'approve',
                    })"
                >
                  <a-icon type="check" />
                  Terima
                </a-menu-item>
                <a-menu-item
                  v-if="val.status === 'ACTIVE' && permission.includes('WRITE')"
                  class="action py-2"
                  @click="$emit(
                    'handleShowModal',
                    {
                      ...val,
                      show: true, 
                      action: 'reject',
                    })"
                >
                  <a-icon type="close" />
                  Batalkan
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </td>
      </tr>
    </tbody>
  </TableBorder>
</template>

<script>
  import TableBorder from '@/components/Table/TableBorder.vue'
  
  export default {
    name: 'Voucher',
    components: {
      TableBorder,
    },
    props: {
      dataList: {
        type: Array,
        default: () => [],
      },
      tHead: {
        type: Array,
        default: () => [],
      },
      permission: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>

<style>
.title-product__availability {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 26px;
  color: #1c1c1c;
}

.search__button {
  color: #fff !important;
  background:#2196F3 !important;
}

.inbound__button {
  color: #FF0854 !important;
  background:#FFF !important;
  border-color:#FF0854 !important;
}
.adjustment__button {
  color: #fff !important;
  background:#FF0854 !important;
}

.program {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #2196F3;
}

.type {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #495057
}

.action:hover {
  background: #e9ecef !important;
}
</style>