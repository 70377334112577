import { getCurrentInstance, reactive, toRefs, onMounted, watch, computed } from 'vue'
import store from '@/store'
import route from '@/router'
import { notification } from 'ant-design-vue';
import debounce from 'lodash/debounce'
import { getPromo, approvePromo } from '@/api/channels/distributor'

export default () => {
  const { $store } = getCurrentInstance().proxy.$root
  const routeName = route.app._route.name
  const state_ = reactive({
    t_head: [
      'checkbox',
      'voucher_type',
      'voucher_name',
      'periode',
      'kuota',
      'status',
      'action',
    ],
    t_head_discount: [
      'checkbox',
      'program_name',
      'discount_level',
      'period',
      'quota',
      'status',
      'action',
    ],
    data_list: [],
    sorting_optional: [
      {
        label: 'Tanggal Buat',
        value: 'createdAt',
      },
    ],
    pagination: {
      size: 20,
      total_items: 0,
    },
    searchParam: route.app._route.query.query || '',
    modal: {},
    loading: false,
  })

  const page = computed(() => {
    return +route.app._route.query.page || 1
  })

  const businessId = computed(() => {
    return Object.keys($store.getters['user/businessListObjectByKey'])[0]
  })

  const permission = computed(() => {
    const type = route.app._route.query.type ? 'voucher' : 'discount'
    return store.getters['user/can'](type, 'reverse')
  })

  const fetchData = debounce(async () => {
    const isPrinciple = store.state.user.restriction_base === 'Business'
    await getPromo({
      channel_id: route.app._route.params.id,
      params: {
        status: '',
        limit: state_.pagination.size,
        page: page.value,
        business_id: businessId.value,
        search: route.app._route.query.search || undefined,
        type: route.app._route.query.type || 'DISCOUNT',
        is_principle: isPrinciple,
        sort: route.app._route.query.sort || 'createdAt',
        order: route.app._route.query.order || 'DESC',
        start_date: route.app._route.query.start_date || '',
        end_date: route.app._route.query.end_date || '',
      },
    })
    .then(({ data }) => {
      state_.data_list = data?.data || []
      state_.pagination.total_items = data?.total_row || 0
    })
    .catch(() => {
      state_.data_list = []
      state_.pagination.total_items = 0
    })
  }, 500)

  const approvalPromotion = async (param) => {
    state_.loading = true
    const start_date = param.start_date.split(' ')[0]
    const end_date = param.end_date.split(' ')[0]
    const quota = param.quota.split('/ ')[1]
    const {
      id,
      business_id,
      promotion_name,
      status,
      type,
      attribute,
      parent,
      code,
      tier,
      seller_base,
      seller_list,
      customer_base,
      customer_list,
      requirements,
      seller_ids,
    } = param 

    await approvePromo({
      business_id: businessId.value,
      channel_id: route.app._route.params.id,
      action: param.action,
      data: {
        id,
        business_id,
        promotion_name,
        status,
        type,
        attribute,
        parent,
        code,
        tier,
        quota: quota,
        start_date: start_date,
        end_date: end_date,
        seller_base,
        seller_list,
        customer_base,
        customer_list,
        requirements,
        seller_ids,
        reason: param.reason || undefined,
      },
    })
    .then(() => {
      notification.success({
        message: param.action == 'approve' ? 'Promo Berhasil Diaktifkan' : 'Promosi Berhasil Dibatalkan',
      })
      setTimeout(() => {
        state_.modal = { show: false}
        fetchData()
      }, 1000)
    })
    .catch((err) => {
      console.error(err)
      notification.error({
        message: param.action == 'approve' ? 'Promo Gagal Diaktifkan' : 'Promosi Gagal Dibatalkan',
      })
    })
    .finally(() => state_.loading = false)
  }

  const changePage = (value) => {
    route.push({
      query: { ...route.app._route.query, page: value, search: undefined, sort: undefined },
    })
  }

  const handleSearch =  debounce((e) => {
    const q = e.target.value
    state_.searchParam = q
    route.push({
      query: {
        ...route.app._route.query,
        search: state_.searchParam || undefined,
        page: undefined,
      },
    })

  }, 500)

  const handleType = (e) => {
    route.push({
      query: {
        ...route.app._route.query,
        type: e,
        page: undefined,
      },
    })
  }

  const handleShowModal = (param) => {
    state_.modal = param
  }

  const handleSubmit = (param) => {
      approvalPromotion(param)
  }

  const toDetail = (id, type, isDetail='') => {
    const query = {
      ...route.app._route.query,
      discount_id: type === 'discount' ? id : undefined,
      voucher_id: type !== 'discount' ? id : undefined,
      is_detail: isDetail ? true : undefined,
    }

    route.push({
      name: `promotion-channel.${type}.add`,
      query,
    })
  }

  watch(
    () => route.app._route.query, () => {
      if (route.app._route.name == routeName) {
        fetchData()
      }
    },
  )

  watch(
    () => permission.value, () => {
      fetchData()
    },
  )

  onMounted(
    () => {
    fetchData()
  })

  return { ...toRefs(state_), changePage, page, handleSearch, handleType, handleShowModal, handleSubmit, toDetail }
}