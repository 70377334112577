<template>
  <a-modal
    :visible="true"
    :footer="null"
    :width="500"
    @cancel="closeModal"
  >
    <a-row>
      <a-col :span="24" class="d-flex justify-content-center">
        <div class="pbz-font subtitle-md-medium">
          {{ modal.action === 'approve' ? 'Konfirmasi' : 'Pembatalan Promo' }}
        </div>
      </a-col>
      <a-col :span="24" class="d-flex justify-content-center" style="margin: 10px 0px">
        <div class="pbz-font" style="text-align:center;">
          <template v-if="modal.action === 'approve'">
            Dengan mengaktifkan Promo <b>{{ modal?.promotion_name }}</b>, 
            maka promo akan dipublikasikan dan dapat digunakan sesuai periode yang berlaku.
          </template>
          <template v-else>
            Dengan membatalkan Promo <b>{{ modal?.promotion_name }}</b>, 
            maka promo akan berstatus tidak aktif dan tidak dapat digunakan.
          </template>
        </div>
      </a-col>
      <div v-if="modal.action == 'reject'">
        <a-col class="pbz-font" style="margin-bottom: 10px">
          <b>Pilih Alasan untuk membatalkan promo ini:</b>
        </a-col>
        <a-col>
          <a-radio-group v-model="cancellation" size="large" :disabled="loadingCancel">
            <a-radio
              v-for="(reason, index) in cancellationReasons"
              :key="index"
              :value="reason"
              class="reason-list"
            >
              {{ reason }}
            </a-radio>
          </a-radio-group>
          <div v-if="cancellation === 'Alasan lain'" style="text-align: right; font-size: 11px; color: grey">
            {{ `${otherCancellation.length}/60` }}
          </div>
          <a-input
            v-if="cancellation === 'Alasan lain'"
            v-model="otherCancellation"
            :placeholder="$t('discount.write_reason')"
            :disabled="loadingCancel"
            :max-length="60"
          />
        </a-col>
      </div>
      <a-col v-if="true" :span="24" class="d-flex justify-content-center" style="margin-top: 30px">
        <a-button
          class="pbz-font"
          style="
            border: 1px solid #E00000;
            background: #E00000;
            color: #FFFFFF;
            width: 100%;
            height: auto;
            padding: .75rem;
            border-radius: 5px;
            cursor: pointer;
          "
          :disabled="loadingCancel"
          @click="submitApproval"
        >
          <div>
            Setuju
            <!-- {{ modal.action === 'approve' ? 'Setuju ' : 'Batalkan ' }} -->
          </div>
          <div v-if="false">
            <a-icon type="loading" />
          </div>
        </a-button>
      </a-col>
      <a-col v-if="true" :span="24" class="d-flex justify-content-center align-items-center">
        <a-button
          class="pbz-font"
          style="
            color: #E00000;
            padding: .75rem;
            cursor: pointer;
            width: 100%;
            height: auto;
            border: none;
          "
          :disabled="loadingCancel"
          @click="closeModal"
        >
          Batal
        </a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  props: {
    modal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      cancellation: '',
      otherCancellation: '',
      loadingCancel: false,
      cancellationReasons: [
        'Promo duplikasi dengan promo lain',
        'Ingin mengubah / menghentikan periode aktif promo',
        'Promo terkait kurang bermanfaat',
        'Alasan lain',
      ],
    }
  },
  methods: {
    submitApproval() {
      if(this.modal.action !== 'approve') {
        if(!this.cancellation || (this.cancellation === 'Alasan lain' && this.otherCancellation.trim() === '')) {
          this.$message.error('Alasan membatalkan promo harus dipilih / diisi')
          return
        }
      }
      this.loadingCancel = true
      this.$emit('handleSubmit', {
        ...this.modal, 
        reason: this.cancellation === 'Alasan lain' ? this.otherCancellation : this.cancellation,
      })
    },
    closeModal() {
      this.loadingCancel = false
      this.cancellation = ''
      this.otherCancellation = ''
      this.$emit('handleShowModal', {show: false})
    },
  },
}
</script>

<style lang="scss">
.reason-list {
  display: block !important;
  width: 100%;
  margin-bottom: 12px !important;

  &:last-child {
    margin-bottom: 0 !important;
  }
}
</style>